import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DiscountPageLayout from '../components/DiscountPageLayout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

/*
 * Template for discount page 
 *
 * @param {object} data
 *
 * @returns {JSX Element}
 */
export default function discountTemplate({ data }) {

  const { allWpDiscount: { discounts },
    wpPage: { title, language, translations },
    allWpMenu,
    allWp: { nodes: [optionsPage] } } = data

  return (
    <>
      <Seo title={title} lang={language.locale} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <DiscountPageLayout discounts={discounts} language={language} sidebarData={optionsPage} />
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}

discountTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export const discountPageQuery = graphql`
  query discountPage($id: String!) {
    wpPage(id: {eq: $id}) {
      translations {
        uri
      }
      language {
        locale
      }
      title
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
        ...getOptionsPageSideBannerData
      }
    }
    allWpDiscount {
      discounts: nodes {
        title
        content
        discount_adress {
          discountAddress
          discountWebAddress
          fieldGroupName
        }
        featuredImage {
          node {
            file: localFile {
              image: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        language {
          locale
        }
        discounts_type {
          nodes {
            name
          }
        }
      }
    }
  }
`