import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import DiscountHeader from './DiscountHeader'
import DiscountsList from './DiscountsList'
/**
 * Archive page layout for Discounts 
 * 
 *  @param {array} discounts - Array of all discounts
 *  @param {object} language current language 
 **/
export default function DiscountPageLayout({ discounts, language, sidebarData }) {

  // tst commit
  const { locale } = language

  const allDiscountsText = locale === 'is_IS' ? 'Allir flokkar' : 'All categories'

  const defaultDiscountType = locale === 'is_IS' ? 'Annað' : 'Other'

  //Get only discounts for current lang
  let currentLanguageDiscounts = discounts.filter(discount => discount.language.locale === locale)

  const [activeDiscountsTaxonomy, setActiveDiscountsTaxonomy] = useState('All')
  const [discountsData, setDiscountsData] = useState(currentLanguageDiscounts)

  //Get discounts taxonomies which will be filters
  let filters = currentLanguageDiscounts.map(discount => discount.discounts_type.nodes[0]?.name ? discount.discounts_type.nodes[0].name : defaultDiscountType)
  //Remove duplicates from array
  filters = new Set(filters)
  //Add all discounts option as 1st item in array
  filters = [allDiscountsText, ...filters]

  useEffect(() => {

    //This happens when user change filter, so first check if chosen taxonomy is different from all discounts
    //If is true then we need to filter discounts / false show all filters
    if (activeDiscountsTaxonomy !== allDiscountsText) {
      //Set empty array 
      setDiscountsData([])
      //Go through all discounts and get discount type array with taxonomy names
      currentLanguageDiscounts.map(discount => {
        // Setting default discount type to Other, if not selected in backend
        if(!discount.discounts_type.nodes[0]) {
          discount.discounts_type.nodes[0] = {
            name: defaultDiscountType
          }
        }
        
        // Go throung taxonomy array and check if propery name is equal with activeDiscountsTaxonomy state
        discount.discounts_type.nodes.map(disc =>
          disc.name === activeDiscountsTaxonomy && setDiscountsData(discountsData =>
            //If is true push item from the first map into discountsData array
            discountsData.concat(discount)
          )
      )})
    } else {
      setDiscountsData(currentLanguageDiscounts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDiscountsTaxonomy])

  useEffect(() => {
    setDiscountsData(currentLanguageDiscounts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="discount-page">
      <div className="discount-page__wrapper">

        <div className="discount-page">
          <DiscountHeader filters={filters} language={locale} setActiveDiscountsTaxonomy={setActiveDiscountsTaxonomy} />
          <div className="content-wrapper">
            <DiscountsList currentLanguageDiscounts={discountsData} sidebarData={sidebarData} />
          </div>

        </div>
      </div>
    </div>
  )
}

DiscountPageLayout.propTypes = {
  discounts: PropTypes.array,
  language: PropTypes.object
}